<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="depositCardPopup"
      header="선불카드 정보"
      width="422"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      :cssClass="
        depositCardInfo.cardId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
      @close="onDepositCardPopupClosed"
    >
      <div class="window prePaymentCardInformation">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">선불카드 정보</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="reset">
                          <erp-button
                              buttonDiv="GET"
                              @click.native="onAddButtonClicked"
                              :isIconCustom="true"
                          >
                            초기화
                          </erp-button>
                        </li>
                        <li>
                          <erp-button
                              buttonDiv="GET"
                              @click.native="onChangeHistoryButtonClicked"
                              :isIconCustom="true"
                          >
                            변경이력
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">카드번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="cardNo"
                                        v-model="depositCardInfo.cardNo"
                                        :disabled="isExistData"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">카드상태</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="depositCardStatus"
                                        v-model="
                                          depositCardInfo.depositCardStatus
                                        "
                                        :dataSource="
                                          inputOptions.depositCardStatusOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">발급/만료일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group dateRange">
                                    <div class="form">
                                      <input-date-range
                                        ref="inputDateRangeValue"
                                        v-model="inputDateRangeValue"
                                        type="lookup-condition"
                                        @change="onInputDateRangeChange"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field memberName">
                              <!-- 필수 : required -->
                              <div class="title required">회원명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="memberName"
                                        v-model="depositCardInfo.memberName"
                                        @change="onMemberNameChanged"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                  <li class="item form-group text">
                                    {{ depositCardInfo.memberNo }}
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li class="search">
                                        <erp-button
                                          button-div="GET"
                                          @click.native="
                                            onMemberPopupOpen(
                                              true,
                                              memberPopupType.RESV,
                                              depositCardInfo.memberName,
                                              null
                                            )
                                          "
                                        >
                                          조회
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        v-model="depositCardInfo.remarks"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">등록자/일시</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group text">
                                    {{ depositCardInfo.insertName }}
                                  </li>
                                  <li class="item form-group text">
                                    {{ depositCardInfo.insertDt }}
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  :ignore="isPopupOpened"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="delete">
              <erp-button
                  buttonDiv="DELETE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  :ignore="isPopupOpened"
                  @click.native="onDeleteButtonClicked"
              >
                삭제
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeDepositCardPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClosed"
    />
  </div>
</template>

<script>
import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";

import {
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCodeAttrbByCodeAndIdx,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import { formPreventEnterEvent, validateFormRefs } from "@/utils/formUtil";
import { getMemberList } from "@/api/member";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import memberSelectPopup from "@/views/common/MemberSelectPopup";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import HistoryPopup from "../../common/HistoryPopup";

export default {
  name: "DepositCardPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputTextarea,
    InputText,
    InputDateRange,
    memberSelectPopup,
    HistoryPopup,
    ErpButton
  },
  created() {
    this.depositCardInfo.issuDate = getFormattedDate(new Date());
    this.depositCardInfo.expirDate = getFormattedDate(new Date());
    this.inputDateRangeValue.from = this.depositCardInfo.issuDate;
    this.inputDateRangeValue.to = this.depositCardInfo.expirDate;
  },
  watch: {
    inputDateRangeValue: {
      deep: true,
      handler() {
        this.depositCardInfo.issuDate = this.inputDateRangeValue.from;
        this.depositCardInfo.expirDate = this.inputDateRangeValue.to;
      },
    },
  },
  data() {
    return {
      isHistoryPopupOpen: false,
      isExistData: false,
      memberPopupType: {
        RESV: "RESV",
      },
      isMemberSelectPopupOpen: false,
      commonCodeFields: { text: "comName", value: "comCode" },
      inputOptions: {
        depositCardStatusOptions: commonCodesGetCommonCode(
          "DEPOSIT_CARD_STATUS"
        ),
      },
      depositCardInfo: {
        cardId: null,
        cardNo: null,
        depositCardStatus: commonCodeGetComCodeDefaultValue(
          "DEPOSIT_CARD_STATUS"
        ),
        depositId: null,
        issuDate: null,
        expirDate: null,
        memberName: null,
        membershipId: null,
        memberNo: null,
        remarks: null,
        insertName: null,
        insertDt: null,
        cprName: null,
      },
      orgDepositCardInfo: {},
      inputDateRangeValue: { to: null, from: null },
      depositId: null,
      depositKind: null,
      depositCardValidationRules: {
        cardNo: {
          required: true,
        },
        depositCardStatus: {
          required: true,
        },
        inputDateRangeValue: {
          required: true,
        },
        memberName: {
          required: true,
        },
      },
      isSave: false,
      memberInfo: {
        memberName: null,
        membershipId: null,
        memberNo: null,
        cprName: null,
      },
    };
  },
  computed: {
    isPopupOpened() {
      return this.isMemberSelectPopupOpen;
    },
  },
  methods: {
    validateFormRefs,
    preventEnterEvent: formPreventEnterEvent,
    async showDepositCardPopup(popupData) {
      const cardId =
        !!popupData && !!popupData.cardId ? popupData.cardId : null;
      this.depositId =
        !!popupData && !!popupData.depositId ? popupData.depositId : null;

      this.depositKind =
        !!popupData && !!popupData.depositKind ? popupData.depositKind : null;

      this.memberInfo.memberName = popupData.memberName;
      this.memberInfo.membershipId = popupData.membershipId;
      this.memberInfo.memberNo = popupData.memberNo;
      this.memberInfo.cprName = popupData.cprName;

      if (cardId) {
        this.isExistData = true;

        await this.fetchDepositCard(cardId);
      } else {
        this.isExistData = false;

        this.depositCardInfo.depositId = this.depositId;
        this.depositCardInfo.memberName = JSON.parse(
          JSON.stringify(popupData.memberName)
        );
        this.depositCardInfo.memberNo = JSON.parse(
          JSON.stringify(popupData.memberNo)
        );
        this.depositCardInfo.membershipId = JSON.parse(
          JSON.stringify(popupData.membershipId)
        );
        this.depositCardInfo.cprName = JSON.parse(
          JSON.stringify(popupData.cprName)
        );

        this.setExpirDate(this.depositCardInfo.issuDate);
      }
    },
    onDepositCardPopupClosed() {
      this.$emit("popupClosed", this.isSave);
    },
    closeDepositCardPopup() {
      this.$refs.depositCardPopup.hide();
    },
    onMemberPopupOpen: function (
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      if (data.selectedRowData) {
        if (!data.selectedRowData.membershipId) {
          this.setNonMemberInfos();
        } else {
          this.setMemberInfos(data.selectedRowData);
        }
      }
    },
    onMemberNameChanged(args) {
      if (this.commonMixinIsButtonDisableByAuth("depositReg")) {
        return;
      }

      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.setNonMemberInfos();
          return;
        }

        getMemberList({ memberNameNo: args.value })
          .then((response) => {
            const memberList = response.value.memberList;
            if (memberList && memberList.length > 0) {
              this.onMemberPopupOpen(
                false,
                this.memberPopupType.RESV,
                args.value,
                null
              );
            } else {
              this.setNonMemberInfos();
            }
          })
          .catch((error) => {
            console.log("getMemberList.err.===>", error);
          });
      }
    },
    setMemberInfos(memberInfo) {
      this.depositCardInfo.memberName = memberInfo.memberName;
      this.depositCardInfo.membershipId = memberInfo.membershipId;
      this.depositCardInfo.memberNo = memberInfo.memberNo;
    },
    setNonMemberInfos() {
      this.depositCardInfo.membershipId = null;
      this.depositCardInfo.memberNo = null;
    },
    init() {
      this.depositCardInfo.cardId = null;
      this.depositCardInfo.cardNo = null;
      this.depositCardInfo.depositCardStatus = commonCodeGetComCodeDefaultValue(
        "DEPOSIT_CARD_STATUS"
      );
      this.depositCardInfo.depositId = this.depositId;
      this.depositCardInfo.issuDate = getFormattedDate(new Date());
      this.depositCardInfo.expirDate = null;
      this.depositCardInfo.memberName = JSON.parse(
        JSON.stringify(this.memberInfo.memberName)
      );
      this.depositCardInfo.memberNo = JSON.parse(
        JSON.stringify(this.memberInfo.memberNo)
      );
      this.depositCardInfo.membershipId = JSON.parse(
        JSON.stringify(this.memberInfo.membershipId)
      );
      this.depositCardInfo.cprName = JSON.parse(
        JSON.stringify(this.memberInfo.cprName)
      );
      this.depositCardInfo.remarks = null;
      this.depositCardInfo.insertName = null;
      this.depositCardInfo.insertDt = null;

      this.setExpirDate(this.depositCardInfo.issuDate, true);

      this.orgDepositCardInfo = JSON.parse(
        JSON.stringify(this.depositCardInfo)
      );

      this.isExistData = false;
    },
    onAddButtonClicked() {
      this.init();
    },
    async onSaveButtonClicked() {
      if (!this.validateFormRefs(this.depositCardValidationRules)) {
        return;
      }

      let depositCard = JSON.parse(JSON.stringify(this.depositCardInfo));

      depositCard.cardNo =
        !depositCard.cardNo || depositCard.cardNo.trim() === ""
          ? null
          : depositCard.cardNo;
      depositCard.depositCardStatus =
        !depositCard.depositCardStatus ||
        depositCard.depositCardStatus.trim() === ""
          ? null
          : depositCard.depositCardStatus;
      depositCard.issueDate =
        !depositCard.issueDate || depositCard.issueDate.trim() === ""
          ? null
          : depositCard.issueDate;
      depositCard.expirDate =
        !depositCard.expirDate || depositCard.expirDate.trim() === ""
          ? null
          : depositCard.expirDate;
      depositCard.memberName =
        !depositCard.memberName || depositCard.memberName.trim() === ""
          ? null
          : depositCard.memberName;
      depositCard.membershipId =
        !depositCard.membershipId || depositCard.membershipId.trim() === ""
          ? null
          : depositCard.membershipId;
      depositCard.memberNo =
        !depositCard.memberNo || depositCard.memberNo.trim() === ""
          ? null
          : depositCard.memberNo;
      depositCard.cprName =
        !depositCard.cprName || depositCard.cprName.trim() === ""
          ? null
          : depositCard.cprName;
      depositCard.remarks =
        !depositCard.remarks || depositCard.remarks.trim() === ""
          ? null
          : depositCard.remarks;

      const depositCardCount = await this.fetchDepositCardCount(
        depositCard.cardNo,
        depositCard.cardId
      );

      if (depositCardCount > 0) {
        this.errorToast(
          this.$t("creditManagement.popupMessage.existDepositCardNo")
        );
        return;
      }

      await this.patchDepositCard(depositCard);
    },
    async patchDepositCard(depositCard) {
      const data = await GolfErpAPI.patchDepositCard(depositCard);

      this.depositCardInfo.cardId = data.cardId ? data.cardId : null;
      this.depositCardInfo.cardNo = data.cardNo ? data.cardNo : null;
      this.depositCardInfo.depositCardStatus = data.depositCardStatus
        ? data.depositCardStatus
        : null;
      this.depositCardInfo.depositId = data.depositId ? data.depositId : null;
      this.depositCardInfo.issuDate = data.issuDate ? data.issuDate : null;
      this.inputDateRangeValue.from = this.depositCardInfo.issuDate;
      this.depositCardInfo.expirDate = data.expirDate ? data.expirDate : null;
      this.inputDateRangeValue.to = this.depositCardInfo.expirDate;
      this.depositCardInfo.memberName = data.memberName
        ? data.memberName
        : null;
      this.depositCardInfo.membershipId = data.membershipId
        ? data.membershipId
        : null;
      this.depositCardInfo.memberNo = data.memberNo ? data.memberNo : null;
      this.depositCardInfo.remarks = data.remarks ? data.remarks : null;
      this.depositCardInfo.insertName = data.insertName
        ? data.insertName
        : null;
      this.depositCardInfo.insertDt = data.insertDt ? data.insertDt : null;

      this.infoToast(this.$t("main.popupMessage.saved"));

      if (this.depositCardInfo.cardId) {
        this.isExistData = true;
      } else {
        this.isExistData = false;
      }

      this.isSave = true;
    },
    async fetchDepositCard(cardId) {
      const data = await GolfErpAPI.fetchDepositCard(cardId);

      this.depositCardInfo.cardId = data.cardId ? data.cardId : null;
      this.depositCardInfo.cardNo = data.cardNo ? data.cardNo : null;
      this.depositCardInfo.depositCardStatus = data.depositCardStatus
        ? data.depositCardStatus
        : null;
      this.depositCardInfo.depositId = data.depositId ? data.depositId : null;
      this.depositCardInfo.issuDate = data.issuDate ? data.issuDate : null;
      this.inputDateRangeValue.from = this.depositCardInfo.issuDate;
      this.depositCardInfo.expirDate = data.expirDate ? data.expirDate : null;
      this.inputDateRangeValue.to = this.depositCardInfo.expirDate;
      this.depositCardInfo.memberName = data.memberName
        ? data.memberName
        : null;
      this.depositCardInfo.membershipId = data.membershipId
        ? data.membershipId
        : null;
      this.depositCardInfo.memberNo = data.memberNo ? data.memberNo : null;
      this.depositCardInfo.remarks = data.remarks ? data.remarks : null;
      this.depositCardInfo.insertName = data.insertName
        ? data.insertName
        : null;
      this.depositCardInfo.insertDt = data.insertDt ? data.insertDt : null;

      this.orgDepositCardInfo = JSON.parse(
        JSON.stringify(this.depositCardInfo)
      );
    },
    async fetchDepositCardCount(cardNo, cardId) {
      return await GolfErpAPI.fetchDepositCardCount(
          cardNo,
          cardId
      );
    },
    async onDeleteButtonClicked() {
      const cardId = this.depositCardInfo.cardId
        ? this.depositCardInfo.cardId
        : null;

      if (cardId === null) {
        this.errorToast(this.$t("main.popupMessage.notExistDepositCardInfo"));
        return;
      }

      if (!(await this.confirm(this.$t("main.popupMessage.confirmDelete")))) {
        return;
      }

      await this.deleteDepositCard(cardId);
      await this.closeDepositCardPopup();
    },
    async deleteDepositCard(cardId) {
      await GolfErpAPI.deleteDepositCard(cardId);

      this.isSave = true;
    },
    onInputDateRangeChange(args) {
      this.setExpirDate(args.data.from);
    },
    setExpirDate(fromDate, initFlag = false) {
      const addMonth = commonCodesGetCommonCodeAttrbByCodeAndIdx(
        "DEPOSIT_KIND",
        this.depositKind,
        2
      );

      if (addMonth) {
        if (fromDate !== this.orgDepositCardInfo.issuDate || initFlag) {
          const date = new Date(fromDate);

          date.setMonth(date.getMonth() + addMonth);

          this.depositCardInfo.expirDate = getFormattedDate(date);

          this.inputDateRangeValue.to = this.depositCardInfo.expirDate;

          this.orgDepositCardInfo.issuDate = JSON.parse(
            JSON.stringify(this.depositCardInfo.issuDate)
          );
        }
      }
    },
    onChangeHistoryButtonClicked() {
      // alert("변경이력 개발 예정");
      if (!this.depositCardInfo.cardId) {
        return;
      }

      GolfErpAPI.fetchDepositCardHistory(this.depositCardInfo.cardId).then((rawHistories) => {
        console.log('rawHistories.===>', rawHistories);
        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              DepositCard: {
                __name__: "선수금 카드 정보",
                cardNo: {
                  name: "카드번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                depositCardStatus: {
                  name: "선수금카드상태",
                  type: "commonCode",
                  groupCode: "DEPOSIT_CARD_STATUS",
                  methods: ["create", "update", "delete"],
                },
                issuDate: {
                  name: "발급일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                expirDate: {
                  name: "만료일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberName: {
                  name: "회원명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                cprName: {
                  name: "법인명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNo: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                remarks: {
                  name: "비고",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              }
            }
          });
        });
      });
    },
    historyPopupClosed() {
      this.isHistoryPopupOpen = false;
    },
  },
};
</script>
