<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="depositReturnRegistrationPopup"
      header="선수금 환불/기타 사용 등록"
      allowDragging="true"
      showCloseIcon="true"
      width="550"
      isModal="true"
      @close="onDepositReturnRegistrationPopupClosed"
    >
      <div class="window depositReturnRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">사용일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group date">
                                    <div class="form">
                                      <input-date
                                        ref="useDate"
                                        format="YYYY-MM-DD"
                                        type="lookup-condition"
                                        v-model="depositUseInfo.useDate"
                                        :disabled="isExistData"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">사용구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="depositUseDiv"
                                        v-model="depositUseInfo.depositUseDiv"
                                        :dataSource="
                                          inputOptions.depositUseDivOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                        :disabled="isExistData"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">사용자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="memberName"
                                        v-model="depositUseInfo.memberName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">입금정보</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="depositIncom"
                                        v-model="depositUseInfo.incomId"
                                        :dataSource="
                                          inputOptions.depositIncomOptions
                                        "
                                        :fields="commonCodeFields"
                                        :allowFiltering="false"
                                        cssClass="body-data-dropdown"
                                        :disabled="isExistData"
                                        @change="onDepositIncomDropDownChange"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">사용금액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="useAmt"
                                        v-model="depositUseInfo.useAmt"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">선수금잔액</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group number">
                                    <div class="form">
                                      <input-number
                                        ref="balanceAmt"
                                        v-model="depositUseInfo.balanceAmt"
                                        :disabled="true"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field remarks">
                              <!-- 필수 : required -->
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        v-model="depositUseInfo.remarks"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="closeDepositReturnRegistrationPopup"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";

import InputDate from "@/components/common/datetime/InputDate";
import InputNumber from "@/components/common/InputNumber";

import {
  commonCodesGetCommonCodeByIdxAttrb,
  commonCodeGetComCodeDefaultValue,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import { formPreventEnterEvent, validateFormRefs } from "@/utils/formUtil";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "DepositReturnRegistrationPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputTextarea,
    InputText,
    InputDate,
    InputNumber,
    ErpButton
  },
  async created() {
    this.depositUseInfo.useDate = getFormattedDate(new Date());
  },
  data() {
    return {
      commonCodeFields: { text: "comName", value: "comCode" },
      depositUseInfo: {
        useId: null,
        useDate: null,
        storeCode: commonCodeGetComCodeDefaultValue("STORE_CODE"),
        depositId: null,
        incomId: null,
        depositUseDiv: "RETURN",
        memberName: null,
        useAmt: 0,
        balanceAmt: 0,
        grpNo: null,
        grpName: null,
        startCourse: null,
        startTime: null,
        remarks: null,
        insertName: null,
        insertDt: null,
      },
      inputOptions: {
        depositUseDivOptions: commonCodesGetCommonCodeByIdxAttrb(
          "DEPOSIT_USE_DIV",
          1,
          "Y"
        ),
        depositIncomOptions: [],
      },
      depositReturnValidationRules: {
        useDate: {
          required: true,
        },
        depositUseDiv: {
          required: true,
        },
        memberName: {
          required: true,
        },
        depositIncom: {
          required: true,
        },
        useAmt: {},
      },
      isExistData: false,
    };
  },
  methods: {
    validateFormRefs,
    preventEnterEvent: formPreventEnterEvent,
    showDepositReturnRegistrationPopup(popupData) {
      const useId = !!popupData && !!popupData.useId ? popupData.useId : null;

      if (useId) {
        this.isExistData = true;
      } else {
        this.isExistData = false;

        this.depositUseInfo.depositId = popupData.depositId;

        const balanceAmt = JSON.parse(JSON.stringify(popupData.balanceAmt));

        this.depositReturnValidationRules.useAmt = {
          required: true,
          min: 1,
          max: balanceAmt,
        };
      }

      this.fetchDepositIncoms(popupData.depositId);
    },
    async fetchDepositIncoms(depositId) {
      const data = await GolfErpAPI.fetchDepositIncoms({
        depositId: depositId,
        depositIncomCancelViewFlag: false,
      });

      this.inputOptions.depositIncomOptions = data.map(item => ({
        ...item,
        comCode: item.incomId,
        comName: item.incomDate + "/" + commonCodesGetComName("INCOM_DIV", item.incomDiv),
      }));
    },
    onDepositReturnRegistrationPopupClosed() {
      this.$emit("popupClosed");
    },
    closeDepositReturnRegistrationPopup() {
      this.$refs.depositReturnRegistrationPopup.hide();
    },
    async onSaveButtonClicked() {
      if (!this.validateFormRefs(this.depositReturnValidationRules)) {
        return;
      }

      let depositUse = JSON.parse(JSON.stringify(this.depositUseInfo));

      depositUse.depositUseDiv =
        !depositUse.depositUseDiv || depositUse.depositUseDiv.trim() === ""
          ? null
          : depositUse.depositUseDiv;
      depositUse.memberName =
        !depositUse.memberName || depositUse.memberName.trim() === ""
          ? null
          : depositUse.memberName;
      depositUse.grpNo =
        !depositUse.grpNo || depositUse.grpNo.trim() === ""
          ? null
          : depositUse.grpNo;
      depositUse.grpName =
        !depositUse.grpName || depositUse.grpName.trim() === ""
          ? null
          : depositUse.grpName;
      depositUse.startCourse =
        !depositUse.startCourse || depositUse.startCourse.trim() === ""
          ? null
          : depositUse.startCourse;
      depositUse.startTime =
        !depositUse.startTime || depositUse.startTime.trim() === ""
          ? null
          : depositUse.startTime;
      depositUse.remarks =
        !depositUse.remarks || depositUse.remarks.trim() === ""
          ? null
          : depositUse.remarks;

      await this.postDepositUse(depositUse);
    },
    async postDepositUse(depositUse) {
      await GolfErpAPI.postDepositUse(depositUse);

      this.infoToast(this.$t("main.popupMessage.saved"));

      this.closeDepositReturnRegistrationPopup();
    },
    onDepositIncomDropDownChange(args) {
      const {
        itemData,
      } = args;

      this.depositUseInfo.useAmt = itemData.balanceAmt;
      this.depositUseInfo.balanceAmt = itemData.balanceAmt;
      this.depositReturnValidationRules.useAmt.max = itemData.balanceAmt;
    },
  },
};
</script>
